import $ from 'jquery'

//#
// Add answer list item.
//
// The list element should be the .worksheet-answer-list containing the items.
const addAnswerListItem = function (list) {
  let firstDummy
  const tmpl = list.find('.field-template').html()
  const newItem = $('<li></li>').html(tmpl)
  const ol = list.find('ol')
  if ((firstDummy = ol.find('li.print-dummy')[0]) != null) {
    newItem.insertBefore($(firstDummy))
  } else {
    ol.append(newItem)
  }
}

//#
// Move to next list item or create a new list item when return is hit in the
// input field of the list.
$(document).on(
  'keydown',
  '.worksheet-answer-list input[type="text"]',
  function (e) {
    if (e.keyCode === 13) {
      const target = $(e.target)
      const li = target.closest('li')
      const ol = li.closest('ol')
      let lis = ol.children('li:not(.print-dummy)')
      const liIndex = lis.index(li)

      if (liIndex === lis.length - 1) {
        addAnswerListItem(target.closest('.worksheet-answer-list'))
        lis = ol.children('li')
      }

      $(lis[liIndex + 1])
        .find('input[type="text"]')
        .trigger('focus')

      e.preventDefault()
    }
  }
)

//#
// Move to next field when return is hit in a single line input field, if there
// are further fields available.
$(document).on('keydown', '.worksheet-answer-single-line', function (e) {
  if (e.keyCode === 13) {
    const target = $(e.target)
    const editables = target
      .closest('.worksheet_section')
      .parent()
      .find(
        'input[type="text"]:visible, input[type="email"]:visible, textarea:visible'
      )
    const targetIndex = editables.index(target)

    if (targetIndex < editables.length - 1) {
      editables[targetIndex + 1].focus()
      e.preventDefault()
    }
  }
})

$(document).on('click', '.worksheet-answer-list .add-item', function (e) {
  e.preventDefault()
  addAnswerListItem($(e.target).closest('.worksheet-answer-list'))
})

$(document).on('click', '.worksheet-answer-list .delete', function (e) {
  e.preventDefault()
  const li = $(e.target).closest('li')
  const ol = li.closest('ol')
  li.remove()
  if (!ol.find('li')[0]) {
    const list = ol.closest('.worksheet-answer-list')
    const tmpl = list.find('.field-template').html()
    ol.append($('<li></li>').html(tmpl))
  }
})

//#
// Manage print shadows.
const updateShadow = function (source) {
  source = $(source)
  const parent = source.parent()
  const target = parent.find('.worksheet-answer-print-shadow')

  let shadow = source
    .val()
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\n/g, '<br />')
  if ($.trim(shadow) === '') {
    shadow = '&nbsp;'
  }
  target.html(shadow)
}

$(document).on('change', '[data-behavior=worksheet-print-shadow]', function () {
  return updateShadow(this)
})

window.onDomChanged('.worksheet-wrapper', function () {
  return this.find('[data-behavior=worksheet-print-shadow]').each(function () {
    return updateShadow(this)
  })
})

//#
// Completion percentage
//

const updateCompletionPercentage = function () {
  const sectionCount = $('.worksheet_section').length
  const completedSections = $('.worksheet_section')
    .toArray()
    .filter(isSectionComplete)
  const percentage = parseInt((completedSections.length * 100) / sectionCount)
  $('.worksheet__progress .progress__percent').text(percentage + '%')
  $('.worksheet__progress .progress-bar')
    .css('width', percentage + '%')
    .attr('title', percentage + '%')
}

const isSectionComplete = function (section) {
  // Any text field is filled
  if (
    $(section)
      .find(
        'textarea:visible, input:not([type=radio]):not([type=checkbox]):not([type=file]):visible'
      )
      .toArray()
      .some((input) => $(input).val().length > 0)
  ) {
    return true
  }
  // Any checkbox or radio button is checked
  if (
    $(section).find('input[type=radio]:checked, input[type=checkbox]:checked')
      .length > 0
  ) {
    return true
  }
  // Active Storage file was uploaded
  if ($(section).find('.active_storage_file').length > 0) {
    return true
  }
  // Active Storage file has been selected
  if ($(section).find('.listed_file').length > 0) {
    return true
  }
  // No inputs (text only "question")
  return $(section).find('textarea:visible, input:visible').length === 0
}

$(() => updateCompletionPercentage())

$(document).on(
  'input',
  '.worksheet_section input, .worksheet_section textarea',
  updateCompletionPercentage
)
$(document).on(
  'change',
  '.worksheet_section input, .worksheet_section textarea',
  updateCompletionPercentage
)
$(document).on(
  'direct_upload:done',
  '.worksheet_section .active_storage_file_uploader_input',
  updateCompletionPercentage
)
$(document).on(
  'direct_upload:removed',
  '.worksheet_section .active_storage_file_uploader_input',
  updateCompletionPercentage
)

//#
// Save button in header
//
$(document).on('click', '.worksheet__actions .worksheet__save', () =>
  $('#worksheet form').trigger('submit')
)
